import React from "react"
import { INLINES } from "@contentful/rich-text-types"
import { graphql, Link } from "gatsby"
import { slugify } from "../util/utilityFunctions"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// Components
import Layout from "../components/layout"
import SEO from "../SEO"
import GA from "../components/ga"
import Image from "../components/image"
import InnerWrapper from "../components/innerWrapper"
import Newsletter from "../components/newsLetter"
import Sidebar from "../components/sidebar"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/blog.module.scss"

const Blog = (props) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <iframe
              title="Unique Title 001"
              src={node.data.uri}
              frameBorder="0"
              allowFullScreen
              loading="lazy"
              width="100%"
              height="100%"
            ></iframe>
          )
        } else if (node.data.uri.includes("https://www.youtube.com/embed/")) {
          return (
            <span className="video-iframe">
              <iframe
                title="Youtube Video"
                src={node.data.uri}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </span>
          )
        } else {
          console.log(node)
          return <a href={node.data.uri}> {node.content[0].value} </a>
        }
      },
      "embedded-asset-block": (node) => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <>
      <Layout>
        {/* <SidebarCart /> */}
        <Sidebar />
        <SEO
          title={`Blog | ${props.data.contentfulBlogPost.title}`}
          description={props.data.contentfulBlogPost.seoDescription}
        />
        <article className={styles.blogPost}>
          <Image image={props.data.contentfulBlogPost.image} />
          <InnerWrapper width={800}>
            <h1 className={styles.title}>
              {props.data.contentfulBlogPost.title}
            </h1>
            <p className={styles.date}>
              <i>
                {props.data.contentfulBlogPost.publishDate} -{" "}
                {props.data.contentfulBlogPost.category}
              </i>
            </p>

            <Link
              to={`/category/${slugify(
                props.data.contentfulBlogPost.category
              )}`}
            />

            <div>
              {documentToReactComponents(
                props.data.contentfulBlogPost.body.json,
                options
              )}
            </div>

            <div className={styles.author}>
              <div className={styles.authorDetails}>
                <span className={styles.authorDetailsName}>
                  By {props.data.contentfulBlogPost.author.name}
                </span>
              </div>
            </div>

            <hr />
            <div className={styles.navigation}>
              {(props.pageContext.previous && (
                <>
                  <div>
                    <p>Previous Post</p>
                    <Link
                      to={`/blog/${slugify(props.pageContext.previous.title)}`}
                      className={styles.previous}
                    >
                      <h4>{props.pageContext.previous.title}</h4>
                    </Link>
                  </div>
                </>
              )) || <div />}

              {props.pageContext.next && (
                <>
                  <div>
                    <p>Next Post</p>
                    <Link
                      to={`/blog/${slugify(props.pageContext.next.title)}`}
                      className={styles.next}
                    >
                      <h4> {props.pageContext.next.title}</h4>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </InnerWrapper>
        </article>
        <Newsletter />
      </Layout>

      <GA
        page={`${slugify(props.data.contentfulBlogPost.title)}`}
        code="UA-147060451-1"
      />
    </>
  )
}

export default Blog

export const query = graphql`
  query ($title: String!) {
    contentfulBlogPost(title: { eq: $title }) {
      title
      seoDescription
      publishDate(formatString: "MMMM D, YYYY")
      category
      author {
        name
      }
      image {
        fluid {
          src
          srcSet
          srcWebp
        }
      }
      body {
        json
      }
    }
  }
`
